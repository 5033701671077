let root = process.env.REACT_APP_API;
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsInJlc0lkIjoxLCJpYXQiOjE2NzM3MTQyMDB9.pATGIYj4UNZ_JIfMElLFyapS-rbWupMX4Mhsz8YRoLU";
const api_key = process.env.REACT_APP_API_KEY;
function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
const api = {
    payment_intent_complete: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/${args.restaurant_stub}/order/${args.order_number}/payment-intent?csdb=${args.csdb}&payment_intent=${args.payment_intent}&payment_intent_client_secret=${args.payment_intent_client_secret}`;


            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'PUT',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    payment_intent_create: (args) => {

        return new Promise((resolve, reject) => {

            let url = `${root}/${args.restaurant_stub}/order/${args.order_number}/payment-intent?env=${args.env || 'test'}`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    order_fetch: (args) => {


        return new Promise((resolve, reject) => {

            let url = `${root}/${args.restaurant_stub}/order/${args.order_number}`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },
    phone_check: (restaurant, phone) => {


        return new Promise((resolve, reject) => {

            let url = `${root}/${restaurant.stub}/phone_check/${phone}`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    order_put: (args) => {


        return new Promise((resolve, reject) => {

            let url = `${root}/${args.restaurant_stub}/orders/`;

            var headers = new Headers();
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);
            args.payload.order.id = args.payload.order.id || makeid(6);

            fetch(url, {
                headers,
                method: 'PUT',
                body: JSON.stringify(args.payload.order)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    fetch: (args) => {


        return new Promise((resolve, reject) => {

            let url = `${root}/${args.restaurant_stub}`;

            var headers = new Headers(

            );
            headers.append("x-api-key", api_key);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

}
export default api;