import React, { useState } from "react";
import '../../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { useTranslation } from "react-i18next";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Spinner } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';

import { LinkContainer } from 'react-router-bootstrap'

import { IoSearch, IoLogoWhatsapp } from "react-icons/io5";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';



import 'moment/locale/es'

function propercase(str) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}


const fn_formatPrice = (price, currency, locale) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'

    }).format(price);

    return price_formatted;

};

const OrderLookup = () => {
    const { t } = useTranslation();

    const routeParams = useParams();
    const {
        order_number,
        restaurant_stub,
    } = routeParams;
    const dispatch = useDispatch();
    const [orderNumber, setOrderNumber] = useState(order_number || "");
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        web_order,
    } = dataReducer;


    const fnSearch = () => {
        if (orderNumber.length < 4) return;

        dispatch({
            type: "ORDER_FETCH",
            restaurant_stub,
            order_number: orderNumber
        });
        navigate(`/${restaurant_stub}/o/${orderNumber}`);

    }

    return <Container
        className="mt-5"
        style={{
            margin: 'auto',
            maxWidth: 350,
        }}
    >

        <Row
            className='mt-1'
        >

            <Col>
                <InputGroup>
                    <InputGroup.Text id="search-icon" >
                        <IoSearch size={25} />
                    </InputGroup.Text>
                    <Form.Control
                        size="lg"
                        placeholder={t('order_number')}
                        value={orderNumber}
                        onChange={(e) => { setOrderNumber(e.target.value.toUpperCase()) }}
                        aria-label="Order Number"
                        aria-describedby="basic-addon1"
                        autoFocus
                        maxLength={6}
                    />
                </InputGroup>
            </Col>
        </Row>

        {web_order?.msg === 'order_not_found' && <Row
            className="mt-4"
        >
            <Col
                style={{
                    textAlign: 'center',
                }}
            >
                {t('order_not_found')}
            </Col>
        </Row>}

        <Row
            className='mt-4'
        >

            <Col
                style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
            >
                <Button

                    onClick={fnSearch}
                    size="lg"
                    style={{
                        width: 150
                    }}
                >
                    {t('search')}
                </Button>
            </Col>
        </Row>

    </Container>
}


const Loading = () => {
    // const { t } = useTranslation();
    return <Container
        style={{
            margin: 'auto',
            maxWidth: 350,
            padding: 50
        }}

    >
        <Alert
            className="div-row-space-between"
        >
            <div></div>
            <Spinner size="lg" />

            <div></div>
        </Alert>
    </Container>
}

const OrderDetails = () => {
    const navigate = useNavigate();
    const routeParams = useParams();

    const { t } = useTranslation();
    const {
        order_number,
        restaurant_stub
    } = routeParams;

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        restaurant,
        web_order
    } = dataReducer;


    //add all costs of json array
    const fn_getTotal = (items) => {
        let total = 0;
        items.forEach(item => {
            total += item.price_ordered;
        });
        return total;
    };

    React.useEffect(() => {


        dispatch({
            type: "MENU_FETCH",
            restaurant_stub
        });
        if (order_number) {
            dispatch({
                type: "ORDER_FETCH",
                restaurant_stub,
                order_number
            });
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    moment.locale(restaurant?.locale)
    return <>
        <HelmetProvider>
            <Helmet>
                <title>{restaurant?.name || "Saga Menu"}</title>
            </Helmet>
        </HelmetProvider>
        <Container
            style={{
                padding: 0
            }}
        >

            <Navbar bg="light" expand="xxl"

            >

                <Container >
                    <LinkContainer to={`/${restaurant_stub}/`} className="div-row" >
                        <Navbar.Brand href="#home" >
                            <div className="div-row">

                                {
                                    restaurant?.header_logo ?
                                        <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 30 }} alt={`${restaurant.name} Logo`} />
                                        :
                                        <>{restaurant?.name}</>
                                }

                            </div>
                        </Navbar.Brand>
                    </LinkContainer>
                    {
                        restaurant?.whatsapp_api && <a href={`https://wa.me/${restaurant.whatsapp_api}`} target="_blank" rel="noreferrer" className='div-row' ><IoLogoWhatsapp />+{restaurant.whatsapp_api}</a>
                    }


                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <LinkContainer to={`/${restaurant_stub}/`} className="div-row" >
                                <Nav.Link >Menu</Nav.Link>
                            </LinkContainer>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>



            {

                !restaurant ||
                    (!web_order && order_number)
                    ||
                    (web_order.fetching)
                    ?
                    <Loading />
                    :


                    web_order?.success === 0
                        ?

                        <OrderLookup />
                        :

                        <Container
                            className="mt-3"
                            style={{
                                maxWidth: 600,
                            }}
                        >
                            <Row>
                                <Col>
                                    Orden {web_order.order.order_id}
                                    <br />
                                    {web_order.order.order_number}
                                </Col>

                                <Col>
                                    {web_order.order.order_name}
                                    <br />
                                    {web_order.order.order_phone}
                                </Col>
                            </Row>
                            {web_order?.order?.order_type !== 'on_site'
                                &&


                                <Row>
                                    <Col>

                                        {propercase(moment(web_order.order.order_dateTime).tz(restaurant?.restaurant_timezone).format("dddd, MMMM D, h:mm a"))}
                                        <br />
                                        <h5>{propercase(web_order.order.ordered_for_day_str)}  {web_order.order.order_time_slot}</h5>
                                    </Col>
                                </Row>
                            }


                            <Table
                                borderless
                                variant='primary'
                                striped
                                hover
                            >
                                <tbody>
                                    {
                                        JSON.parse(web_order.order.order_items_json).map((item, index) => {
                                            return <tr key={index} >
                                                <td  >

                                                    {item?.units_ordered}
                                                </td>
                                                <td>
                                                    {item?.name}
                                                    {item?.extras_ordered.map((e, index) => {
                                                        return <div key={index} style={{ fontSize: 12 }}>{e.name}</div>
                                                    })}
                                                    <div>
                                                        {item?.required_option?.name}
                                                    </div>

                                                </td>
                                                <td style={{ textAlign: 'right' }} >
                                                    {fn_formatPrice(item?.price_ordered, restaurant.currency, restaurant.locale)}
                                                </td>

                                            </tr>
                                        }
                                        )
                                    }




                                    {web_order?.order?.order_delivery_cost > 0 &&
                                        <tr>
                                            <td colSpan={3} style={{ textAlign: 'right' }}>
                                                Delivery
                                                &nbsp;&nbsp;
                                                {fn_formatPrice(web_order?.order?.order_delivery_cost, restaurant.currency, restaurant.locale)}
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '1.3em' }}>
                                            {fn_formatPrice(fn_getTotal(JSON.parse(web_order.order.order_items_json)) + (web_order?.order?.order_delivery_cost || 0), restaurant.currency, restaurant.locale)}
                                        </td>
                                    </tr>


                                </tbody>
                            </Table>

                            {web_order?.order.order_paid_dt === null &&
                                <div
                                    className="div-row-space-between"
                                >
                                    <>&nbsp;</>
                                    <Button
                                        style={{
                                            width: 200,

                                        }}
                                        onClick={() => {
                                            navigate(`/${restaurant_stub}/${web_order.order.order_number}/pay/`)
                                        }}
                                    >
                                        {t('pay_now')}
                                    </Button>
                                </div>

                            }

                        </Container>



            }
        </Container>
    </>
}

export default OrderDetails;