import React from "react";
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';

import Table from 'react-bootstrap/Table';


import { useTranslation, Trans } from "react-i18next";

import { LinkContainer } from 'react-router-bootstrap'

import CheckoutForm from "./CheckoutForm";



import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import api from '../api';

//const img_root = process.env.REACT_APP_IMG_URL;


const stripePromise = loadStripe(process.env.REACT_APP_ENV === 'live' ? "pk_live_51MreAZFL6pGT7PevPIy1TUgebForEQf6uE3wCMWzwh74N7Jme35tdQfhoGdwu5zM1PmTZhDOIsxGkPAmArjb14ty00wDfHuVz3" : "pk_test_51MreAZFL6pGT7Pev3jmKMNVGUlqKZKYWG1fu9R2gXTsjBf5NTWMoRpUcaXtliwHlJQBF1Mx08jwGoLc9Z5Wqs2Cx00rWmwmty1");
//const stripePromise = loadStripe("pk_live_51MreAZFL6pGT7PevPIy1TUgebForEQf6uE3wCMWzwh74N7Jme35tdQfhoGdwu5zM1PmTZhDOIsxGkPAmArjb14ty00wDfHuVz3");

function propercase(str) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

const fn_formatPrice = (price, currency, locale) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'

    }).format(price);

    return price_formatted;

};

function Pay() {
    // eslint-disable-next-line 
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const {
        order_number,
        restaurant_stub,
    } = routeParams;
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        restaurant,
        menu
    } = dataReducer;

    const [clientSecret, setClientSecret] = React.useState("");

    const [order, setOrder] = React.useState();
    const [showOrder, setShowOrder] = React.useState(false);
    const [orderTotalFormatted, setOrderTotalFormatted] = React.useState();
    const [orderTotal, setOrderTotal] = React.useState();

    const [expanded, setExpanded] = React.useState(false);
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };



    React.useEffect(() => {


        if (menu) return;
        dispatch({
            type: "MENU_FETCH",
            restaurant_stub
        });


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        api.payment_intent_create({
            order_number,
            restaurant_stub,
            env: process.env.REACT_APP_ENV
        }).then((res) => {
            setOrderTotal(res?.data?.order_total);
            setOrder(res?.data?.order);
            setOrderTotalFormatted(res?.data?.order_total_formatted);
            setClientSecret(res?.data?.clientSecret);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {


        if (restaurant) {
            i18n.changeLanguage(restaurant.locale);
        }

    }, [restaurant]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!restaurant) {
        return <Container
            style={{
                margin: 'auto',
                maxWidth: 350,
                padding: 50
            }}

        >
            <Alert
                className="div-row-space-between"
            >
                <div></div>
                <Spinner size="lg" />

                <div></div>
            </Alert>
        </Container>
    }

    return (<>

        <div
            style={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>{restaurant?.name || "Saga Menu"}</title>
                </Helmet>
            </HelmetProvider>
            <Container

            >

                <Navbar
                    bg="light"
                    expand="lg"
                    expanded={expanded}
                    style={{
                        paddingBottom: 5, display: 'flex', flexDirection: 'column', justifyItems: 'center',
                        margin: 'auto'
                    }}
                    onToggle={() => setExpanded(!expanded)}
                >

                    <Container >

                        {!restaurant ?

                            <Spinner animation="grow" />
                            :
                            <LinkContainer to={`/${restaurant_stub}/`} className="div-row" >

                                <Navbar.Brand href="#home" >
                                    <div className="div-row">
                                        {
                                            restaurant.header_logo ?
                                                <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 30 }} alt={`${restaurant.name} Logo`} />
                                                :
                                                <>{restaurant.name}</>
                                        }

                                    </div>
                                </Navbar.Brand>
                            </LinkContainer>
                        }


                    </Container>
                </Navbar>


                <Container style={{
                    padding: 0,
                    paddingTop: 10,
                }}>





                    {order ?
                        <Container>
                            <div
                                className="div-row-space-between"
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                <div style={{
                                    width: 50
                                }}></div>
                                <h1 style={{ textAlign: 'center', padding: 0, margin: 0 }}>{orderTotalFormatted}</h1>

                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => setShowOrder(true)}
                                >
                                    {t('order_details')}
                                </Button>
                            </div>
                            {order.order_paid_dt ?
                                <Alert variant="success" style={{ textAlign: 'center' }}>
                                    <Alert.Heading>
                                        <Trans i18nKey="order_has_been_paid"></Trans>


                                    </Alert.Heading>
                                </Alert>
                                :

                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm
                                        restaurant={restaurant}
                                        order={order}
                                        clientSecretDatabase={clientSecret}
                                        pay_now_label={t('pay_now')}
                                    />
                                </Elements>
                            }
                        </Container>
                        :
                        <Spinner animation="grow" />
                    }



                </Container>


                <div style={{
                    height: 200
                }}>
                </div>
            </Container>

            <Modal show={showOrder}
                animation={false}
                style={{ backgroundColor: '#00000080', paddingTop: 100 }}
                small
                top
                onHide={() => setShowOrder(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {order?.order_name}
                    </Modal.Title>

                </Modal.Header>
                {order &&
                    <Modal.Body
                    >
                        <b>{t(order.order_type)}</b>

                        {order.order_type !== 'on_site'
                            &&
                            <>
                                &nbsp;{propercase(order.ordered_for_day_str)}  {order.order_time_slot}
                            </>
                        }

                        <br /><br />

                        <Table
                            borderless
                            variant='primary'
                            striped
                            hover
                        >
                            <tbody>
                                {
                                    (order.order_items_json)
                                    &&
                                    JSON.parse(order?.order_items_json)?.map((item, index) => {
                                        return <tr key={index}>
                                            <td>
                                                {item?.units_ordered}
                                            </td>
                                            <td>
                                                {item?.name}
                                                {item?.extras_ordered.map((e, index) => {
                                                    return <div key={index} style={{ fontSize: 12 }}>{e.name}</div>
                                                })}
                                                <div>
                                                    {item?.required_option?.name}
                                                </div>

                                            </td>
                                            <td style={{ textAlign: 'right' }} >
                                                {fn_formatPrice(item?.price_ordered, restaurant.currency, restaurant.locale)}
                                            </td>

                                        </tr>

                                    }
                                    )
                                }

                                {order.order_delivery_cost > 0 &&
                                    <tr>
                                        <td colSpan={3} style={{ textAlign: 'right' }}>
                                            Delivery
                                            &nbsp;&nbsp;
                                            {fn_formatPrice(order.order_delivery_cost, restaurant.currency, restaurant.locale)}
                                        </td>
                                    </tr>
                                }

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '1.3em' }}>
                                        {fn_formatPrice(orderTotal / 100, restaurant.currency, restaurant.locale)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                }
            </Modal>


        </div>

    </>
    );
}

export default Pay;
