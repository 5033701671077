import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    ButtonGroup,
    Button,
    Form,
    Alert,
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { IoMdCalendar, } from "react-icons/io";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)


const fnDaysClosed = (date) => {
    let m = (date.getMonth() + 1).toString().padStart(2, '0')
    let d = (date.getDate().toString().padStart(2, '0'))


    if (m === '12' && d === '22') return true;
    if (m === '12' && d === '23') return true;
    if (m === '12' && d === '24') return true;
    if (m === '12' && d === '25') return true;
    if (m === '12' && d === '31') return true;
    if (m === '01' && d === '01') return true;

    return false;
}


const TimeSlotSelector = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const WEEK_DAYS_SPANISH = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

    const LAST_WORKING_HOUR = 16;
    const HOURS_ANTICIPATION = 2;

    const [selectedDayOfWeek, setSelectedDayOfWeek] = useState();
    const [dateSelectedFromCalendar, setDateSelectedFromCalendar] = useState(false);

    const [DAYS, setDAYS] = React.useState([])
    const [day, setDay] = useState();
    const [timeSlot, setTimeSlot] = useState("0");
    const [startFromTimeSlot, setStartFromTimeSlot] = useState(null);
    //const [selectedDay, setSelectedDay] = useState({});

    //const [TIME_SLOTS, setTIME_SLOTS] = useState([])

    const [orderDate, setOrderDate] = React.useState(new Date());
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        order,
        restaurant,
    } = dataReducer;

    const startDate = new Date();
    //end date is 3 weeks from startDate
    const endDate = new Date(startDate.getTime() + (21 * 24 * 60 * 60 * 1000));


    const fnCalculateTimeSlot = () => {
        let current_time = parseInt(moment().tz(restaurant.restaurant_timezone).format("HH")) + HOURS_ANTICIPATION;
        let index = (restaurant?.restaurant_time_slots?.findIndex(t => t.time_format === current_time))
        return index;
    }



    const fnWeekDay = (day, locale) => {
        if (day > 5) day = day - 7;
        if (locale.substring(0, 2) === 'es') {
            return WEEK_DAYS_SPANISH[day + 1];
        } else {
            return WEEK_DAYS[day + 1];
        }
    }
    React.useEffect(() => {

        let _days = [{
            label: t('Today'),
            day: 0,
        },
        {
            label: t('Tomorrow'),
            day: 1,
        },
        {
            label: fnWeekDay(moment().tz(restaurant.restaurant_timezone).weekday() + 2, restaurant.locale),
            day: 2,
        },
        {
            label: fnWeekDay(moment().tz(restaurant.restaurant_timezone).weekday() + 3, restaurant.locale),
            day: 3,
        }
        ]

        let current_time = parseInt(moment().tz(restaurant.restaurant_timezone).format("HH")) + 1;
        if (current_time >= LAST_WORKING_HOUR) {
            _days.shift()
        }


        setDAYS(_days)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //when orderDate changes, update the day and timeSlot
    React.useEffect(() => {
        let _order_date = moment(orderDate).tz(restaurant.restaurant_timezone).format("YYYY-MM-DD");
        const dayOfWeek = moment(_order_date).tz(restaurant.restaurant_timezone).day();

        setSelectedDayOfWeek(dayOfWeek);
        console.log(_order_date, dayOfWeek);


        // format orderDate to YYYY-MM-DD format timezone restaurant 
        dispatch({
            type: "ORDER_SET",
            order: {
                ...order,
                day: null,
                timeSlot: null,
                orderDate: _order_date
            }
        });
    }, [orderDate]); // eslint-disable-line react-hooks/exhaustive-deps



    return <Container
        style={{
            height: 350
        }}

    >

        <ButtonGroup aria-label="Days Selector" style={{ width: '100%', marginTop: 5 }}>

            {
                DAYS?.map((item, index) => {
                    return <Button
                        key={index}
                        variant={(day === item.day && !dateSelectedFromCalendar) ? 'primary' : 'light'}
                        onClick={() => {
                            setTimeSlot("0");
                            setDateSelectedFromCalendar(false);

                            if (item.day === 0) {
                                let index_time_slots = fnCalculateTimeSlot();

                                setStartFromTimeSlot(index_time_slots);
                            } else {
                                setStartFromTimeSlot(null);
                            }
                            setDay(item.day);
                            //setSelectedDay(item);

                            const currentDate = moment().tz(restaurant.restaurant_timezone);
                            const futureDate = currentDate.clone().add(item.day, 'days');
                            const dayOfWeek = futureDate.day();
                            setSelectedDayOfWeek(dayOfWeek);
                            setOrderDate(futureDate.toDate());
                            console.log("dayOfWeek -> ", dayOfWeek);
                            dispatch({
                                type: "ORDER_SET",
                                order: {
                                    ...order,
                                    day: item.day,
                                    timeSlot: null
                                }
                            });
                        }}
                    >{item.label}</Button>
                })
            }

        </ButtonGroup>



        <Container
            className="
                mt-1 
                mb-2
                pt-2
                d-flex
                justify-content-center align-items-center
                "

        >

            <DatePicker
                onFocus={e => e.target.blur()}
                className="date-picker-custom"
                selected={orderDate}
                onChange={(date) => {
                    setOrderDate(date);
                    setDay(0);
                    setTimeSlot("0");
                    setDateSelectedFromCalendar(true);
                }}
                locale={'es'}
                minDate={startDate}
                maxDate={endDate}
                popperPlacement="bottom"
            />
            <IoMdCalendar size={28} style={{ marginLeft: -30, zIndex: 999 }} />
        </Container>



        {
            fnDaysClosed(orderDate) ?

                <Alert
                    className="mt-3 text-center"
                >
                    {t("closed_day")}
                </Alert>
                :


                <>
                    {!(!day && day !== 0) ? <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >

                            {
                                restaurant.restaurant_time_slots?.map((el, index) => {

                                    if (
                                        (
                                            startFromTimeSlot === null
                                            ||
                                            index >= startFromTimeSlot
                                        )
                                    ) {

                                        //if (!el.weekend && selectedDay.isWeekend) return null;
                                        //console.log(el.days.indexOf(selectedDayOfWeek))

                                        if (el.days.indexOf(selectedDayOfWeek) === -1) return null;

                                        return <div className="checkboxOne">
                                            <Form.Check
                                                onChange={(e_c) => {
                                                    setTimeSlot(el.label);
                                                    dispatch({
                                                        type: "ORDER_SET",

                                                        order: {
                                                            ...order,
                                                            timeSlot: el.label
                                                        }
                                                    });
                                                }}
                                                key={index}
                                                style={{
                                                    width: 150,
                                                    margin: '5px 0px'
                                                }}
                                                checked={timeSlot === el.label}
                                                type={'radio'}
                                                label={el.label}
                                                id={`time${el.value}`}
                                                name="group1"
                                                disabled={!day && day !== 0}
                                            />

                                        </div>
                                    } else {
                                        return null;
                                    }
                                })
                            }

                        </div>



                    </>


                        :

                        <Alert
                            className="mt-3 text-center"
                        >
                            {t("select_day")}
                        </Alert>

                    }
                </>
        }
    </Container>
}

export default TimeSlotSelector;