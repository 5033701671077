import './App.css';

function App() {

  //if url = www.example.com rediret to /example
  //if url = www.example.com/example redirect to /example
  if (window.location.host === 'pasteleriaevelia.net'
    || window.location.host === 'www.pasteleriaevelia.net'
    || window.location.host === 'localhost:3000'
  ) {
    window.location.href = '/evelia';
    return null;
  }

  return (
    <div className="App">
      404
    </div>
  );
}

export default App;
