import React from "react";
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';

import { LinkContainer } from 'react-router-bootstrap'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';

import Nav from 'react-bootstrap/Nav';

import { IoLogoWhatsapp } from "react-icons/io5";

import { useTranslation, Trans } from "react-i18next";

import api from '../api';



function PayComplete() {
    // eslint-disable-next-line 
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const routeParams = useParams();
    const {
        order_number,
        restaurant_stub,
    } = routeParams;
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        restaurant,
        menu
    } = dataReducer;

    const [expanded, setExpanded] = React.useState(false);
    const [order, setOrder] = React.useState();
    const [paymentResult, setPaymentResult] = React.useState({

    });


    const payment_intent_client_secret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const payment_intent = new URLSearchParams(window.location.search).get(
        "payment_intent"
    );
    const csdb = new URLSearchParams(window.location.search).get(
        "csdb"
    );

    React.useEffect(() => {


        if (menu) return;
        dispatch({
            type: "MENU_FETCH",
            restaurant_stub
        });


    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {

        api.payment_intent_complete({
            order_number,
            restaurant_stub,
            payment_intent,
            payment_intent_client_secret,
            env: process.env.REACT_APP_ENV,
            csdb

        }).then((res) => {
            setPaymentResult({
                success: (res.order.order_paid_dt !== null),
                paymentResult: true,
                msg: res.msg
            })
            setOrder(res.order);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {


        if (restaurant) {
            i18n.changeLanguage(restaurant.locale);
        }

    }, [restaurant]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!restaurant) {
        return <Container
            style={{
                margin: 'auto',
                maxWidth: 350,
                padding: 50
            }}

        >
            <Alert
                className="div-row-space-between"
            >
                <div></div>
                <Spinner size="lg" />

                <div></div>
            </Alert>
        </Container>
    }

    return (<>

        <div
            style={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <title>{restaurant?.name || "Saga Menu"}</title>
                </Helmet>
            </HelmetProvider>
            <Container

            >

                <Navbar
                    bg="light"
                    expand="lg"
                    expanded={expanded}
                    style={{
                        paddingBottom: 5, display: 'flex', flexDirection: 'column', justifyItems: 'center',
                        margin: 'auto'
                    }}
                    onToggle={() => setExpanded(!expanded)}
                >

                    <Container >

                        {!restaurant ?

                            <Spinner animation="grow" />
                            :
                            <LinkContainer to={`/${restaurant_stub}/`} className="div-row" >
                                <Navbar.Brand href="#" >
                                    <div className="div-row">
                                        {
                                            restaurant.header_logo ?
                                                <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 30 }} alt={`${restaurant.name} Logo`} />
                                                :
                                                <>{restaurant.name}</>
                                        }

                                    </div>
                                </Navbar.Brand>
                            </LinkContainer>
                        }


                    </Container>
                </Navbar>


                <Container style={{
                    padding: 0
                }}>
                    {order &&

                        paymentResult.success ?


                        <Alert variant="success">
                            <Alert.Heading>
                                <Trans i18nKey="order_payment_succesful" components={{ whatsapp: <IoLogoWhatsapp /> }} />

                            </Alert.Heading>
                            Orden# {order.order_number} <b>{order.order_total_formatted}</b>
                            <hr />
                            <div>
                                <Nav.Link
                                    target="_blank" rel="noreferrer" href={`https://wa.me/${restaurant.whatsapp_api}`} className="div-row"

                                ><IoLogoWhatsapp />+{restaurant.whatsapp_api}</Nav.Link>
                            </div>
                        </Alert>
                        :

                        paymentResult.paymentResult &&
                        <Alert variant="danger">
                            <Alert.Heading>{t('order_payment_failed')}</Alert.Heading>
                            {paymentResult.msg}

                        </Alert>



                    }

                </Container>

            </Container>

        </div>

    </>
    );
}

export default PayComplete;
