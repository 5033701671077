import { useState } from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const cityBounds = {
    north: 40.43822,
    south: 40.38071,
    east: -3.65379,
    west: -3.76366,
}

const Address = ({
    placeHolder,
    fnSelectAddress,
    fnNoAddress
}) => {
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
        placesService
    } = useGoogle({
        apiKey: API_KEY,
        options: {
            bounds: cityBounds,

            strictBounds: true,
            types: ['address'],
        },
        restrictions: { country: 'es' }
    });
    const [value, setValue] = useState("");
    const [selected, setSelected] = useState(false);


    /*\\
        useEffect(() => {
            // fetch place details for the first element in placePredictions array
            if (placePredictions.length)
                placesService?.getDetails(
                    {
                        placeId: placePredictions[0].place_id,
                    },
                    (placeDetails) => console.log(placeDetails)
                );
        }, [placePredictions]);
    */
    return (
        <div>
            <Form.Control
                autoFocus
                type="text"
                name="address"
                style={{ color: "black" }}
                value={value}
                placeholder={placeHolder}
                onChange={(evt) => {
                    setSelected(false);
                    getPlacePredictions({
                        input: evt.target.value
                    });
                    setValue(evt.target.value);
                    if (fnNoAddress) fnNoAddress();
                }}
                OnBlur={() => {
                    setSelected(false);
                    fnSelectAddress(value)
                }}
                loading={isPlacePredictionsLoading}
            />
            <div
                style={{
                    marginTop: "0px",
                    width: '90%',
                    maxHeight: "200px",
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                    position: "absolute",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    padidng: "10px",
                    zIndex: 1000,
                }}
            >


                {!isPlacePredictionsLoading && !!placePredictions.length && !selected &&
                    <>
                        {
                            placePredictions.map((prediction) => (
                                prediction.description.split(", ")[prediction.description.split(",").length - 2] === 'Madrid' &&
                                <Button
                                    variant="transparent"
                                    onClick={() => {
                                        setValue(prediction.structured_formatting.main_text);
                                        setSelected(true);

                                        placesService?.getDetails(
                                            {
                                                placeId: prediction.place_id,
                                            },
                                            (placeDetails) => fnSelectAddress(prediction.structured_formatting.main_text, prediction, placeDetails)
                                        );
                                    }}

                                    style={{
                                        minWidth: 320,
                                        textAlign: "left",
                                    }}
                                >
                                    {prediction.description.split(", ").slice(0, -1).join(", ")}
                                </Button>
                            ))
                        }
                    </>
                }
            </div>
        </div>
    )

}

export default Address;
