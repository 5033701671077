import React, { useState } from "react";
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import {
    Modal,
    CloseButton,
    Container,
    ButtonGroup,
    Button,
    Form,
    Row,
    Col,
    Table,
    Alert,
    Spinner,
} from 'react-bootstrap';

import { IoCart, IoChevronBack, IoTrash, IoHome } from "react-icons/io5";
import { useTranslation, Trans } from "react-i18next";
//moment().tz("America/Los_Angeles").format();

import api from '../api';

import Address from '../components/Address';
import TimeSlotSelector from "../components/TimeSlotSelector";

let Element = Scroll.Element;
let scroller = Scroll.scroller;



let restaurant_lat = 40.44543201059881;
let restaurant_lng = -3.7030458846556282;

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180)
}


const fn_formatPrice = (price, currency, locale, stripSymbols) => {
    if (!price) return "";
    let price_formatted = new Intl.NumberFormat(locale, {
        //trailingZeroDisplay: "stripIfInteger",
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'

    }).format(price);
    if (stripSymbols) {
        //remove non numeric and , and . symbols
        price_formatted = price_formatted.replace(/[^0-9.,]/g, '');

    }
    return price_formatted;

};
const Order = ({
    handleClose,
    showItemModal
}) => {




    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        order,
        restaurant,
        placing_order,
        order_placed,


    } = dataReducer;


    const fnCalculateDeliveryCost = (distanceKm) => {
        //todo: move rates to the backend
        // https://trello.com/c/2dv6lTad
        if (distanceKm < 3) return 5;
        if (distanceKm < 5) return 7;
        if (distanceKm < 10) return 9;
        if (distanceKm < 14) return 15;
        if (distanceKm < 21) return 50;
        setDeliveryNotAvailable(true);
        return null;
    }



    const [deliveryNotAvailable, setDeliveryNotAvailable] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const handleCloseMessage = () => {

        setErrorMessage(null);
        setShowMessageModal(false);
    }


    const [orderType, setOrderType] = useState("pickup");
    const [orderStep, setOrderStep] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);

    const [name, setName] = useState(order.name || localStorage.getItem('order_name') || '');
    const [phone, setPhone] = useState(order.phone || localStorage.getItem('order_phone') || '');
    const [tableNumber, setTableNumber] = useState(order.tableNumber || '');
    const [address, setAddress] = useState(order.address || localStorage.getItem('order_address1') || '');
    const [address2, setAddress2] = useState(order.address2 || localStorage.getItem('order_address2') || '');

    const [checkingPhone, setCheckingPhone] = useState(false);
    const [phoneValidated, setPhoneValidated] = useState(false);

    const [deliveryCost = 0, setDeliveryCost] = useState();
    //const [distanceKm, setDistanceKm] = useState();

    const fnCalculateOrderTotal = () => {

        let _orderTotal = 0;
        order.items.forEach((item) => {
            _orderTotal += parseFloat(item?.units_ordered * item?.price_ordered);
        });
        setOrderTotal(_orderTotal);

    }


    React.useEffect(() => {
        dispatch({
            type: "ORDER_SET",

            order: {
                ...order,
                orderType
            }
        })
    }, [orderType]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fnCalculateOrderTotal();
    }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

    const fnGoStep1 = async () => {

        let errors = [];
        if (name.trim() === '') errors.push('error_name');
        if (phone.trim() === '') errors.push('error_phone');

        if (errors.length > 0) {
            setErrorMessage({
                title: 'error',
                messages: errors
            });
            setShowMessageModal(true)
            return
        }

        order.name = name.trim();
        order.phone = phone.trim();

        if (phoneValidated) {
            setOrderStep(1);
            return;
        }

        setCheckingPhone(true);
        let phone_check = await api.phone_check(restaurant, "+" + order.phone);

        setCheckingPhone(false);
        if (!phone_check.success) {

            let _msg = {
                title: 'phone_check_error',
                messages: phone_check.validation_errors.map(e => 'phone_check_error_' + e)
            }
            _msg.messages.push("phone_required_format");
            setErrorMessage(_msg);

            setShowMessageModal(true)
        } else {
            setPhoneValidated(true);
            phone_check.phoneNumber = phone_check.phoneNumber.replace(/[^0-9]/g, '');
            setPhone(phone_check.phoneNumber);
            order.phone = phone_check.phoneNumber.trim();
            if (!!phone_check?.cust_summary[0]?.order_count) {
                setShowMessageModal({
                    message: `Tienes ${phone_check?.cust_summary[0]?.order_count} pedidos con nosotros, gracias por tu preferencia y continúa acumulando puntos en Evelia!`,
                });
            } else {
                setShowMessageModal({
                    message: `Gracias! Esta es tu primera orden con nosotros, continúa acumulando puntos!!`,
                });
            }
            setOrderStep(1);

        }

    }


    const fnConfirmOrder = () => {



        let errors = [];

        if (orderType === 'delivery' || orderType === 'pickup') {

            if (!order.orderDate) errors.push('error_day');
            if (!order.timeSlot || order.timeSlot === '0') errors.push('error_time_slot');

        } else {
            if (tableNumber.trim() === '') errors.push('error_table_number');
        }


        if (orderType === 'delivery' && !deliveryCost) {
            errors.push('must_select_delivery_address');

        }

        if (errors.length > 0) {
            setErrorMessage({
                title: 'error',
                messages: errors
            });
            setShowMessageModal(true)
            return
        }

        order.name = name.trim();
        order.phone = phone.trim();
        order.tableNumber = tableNumber.trim();



        if (orderType === 'delivery') {
            if (address.trim() === '') errors.push('address_required');
        }
        if (errors.length > 0) {


            let _msg = {
                title: 'error',
                messages: errors
            }
            setErrorMessage(_msg);

            setShowMessageModal(true)
            return
        }


        dispatch({
            type: 'PLACING_ORDER_START'
        });
        setOrderStep(3);
        dispatch({
            type: 'ORDER_CONFIRM',
            payload: {
                order
            }
        });
    }

    const OrderItems = ({
        size
    }) => {
        const [changeOrderModal, setChangeOrderModal] = React.useState(false);
        const [units, setUnits] = useState(1);

        return <Container
            style={{
                padding: 0
            }}
        >
            <Modal show={changeOrderModal}
                style={{ backgroundColor: '#00000080', paddingTop: 100 }}
                small
                top
                onHide={() => setChangeOrderModal(false)}
            >
                <Modal.Header>
                    {changeOrderModal?.item?.name}
                </Modal.Header>
                <Modal.Body>
                    <div className="div-row-space-between">
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="success" style={{ width: 60 }} onClick={() => units > 0 ? setUnits(units - 1) : 1}>-</Button>
                            <Button variant="light" style={{ width: 60 }}>{units}</Button>
                            <Button variant="success" style={{ width: 60 }} onClick={() => setUnits(units + 1)}>+</Button>
                        </ButtonGroup>



                        <Button variant="primary" onClick={() => {

                            let _order = order;
                            if (units > 0) {
                                _order.items[changeOrderModal.index].units_ordered = units;
                            } else {
                                _order.items.splice(changeOrderModal.index, 1)
                            }
                            dispatch({
                                type: 'ORDER_SET',
                                order: _order
                            });
                            fnCalculateOrderTotal();
                            setChangeOrderModal(false);
                        }}>
                            {t('OK')}
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>

            <div

            >
                <Table

                    borderless
                    variant='primary'
                    striped
                    hover
                >
                    <tbody>
                        {
                            order.items.map((item, index) => {
                                return <tr key={index}
                                    onClick={() => {
                                        setUnits(item.units_ordered);
                                        setChangeOrderModal({
                                            item: item,
                                            index
                                        });

                                    }}
                                >
                                    <td  >

                                        {item?.units_ordered}
                                    </td>
                                    <td>
                                        {item?.name}
                                        {item?.extras_ordered.map((e, index) => {
                                            return <div key={index} style={{ fontSize: 12 }}>{e.name}</div>
                                        })}
                                        <div>
                                            {item?.required_option?.name}
                                        </div>

                                    </td>
                                    <td style={{ textAlign: 'right' }} >
                                        {fn_formatPrice(item?.price_ordered, restaurant.currency, restaurant.locale)}
                                    </td>

                                </tr>
                            }
                            )
                        }

                    </tbody>
                </Table>
            </div>

        </Container >
    }

    const OrderTotal = () => {
        return <Row>
            <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {!!deliveryCost &&
                    <>
                        {fn_formatPrice(orderTotal, restaurant.currency, restaurant.locale, true)}
                        &nbsp;
                        +
                        &nbsp;
                        <div style={{ textAlign: 'center', lineHeight: 1 }}>
                            Delivery
                            <br />
                            {fn_formatPrice(deliveryCost, restaurant.currency, restaurant.locale, true)}
                        </div>
                        &nbsp;

                        &nbsp;
                    </>
                }
                &nbsp;&nbsp;
                <h2>
                    {fn_formatPrice(orderTotal + (deliveryCost || 0), restaurant.currency, restaurant.locale)}
                </h2>
            </Col>
        </Row>




    }

    const NamePhoneForm = () => {

        return <Row

        >
            <Form.Group className="mb-3" as={Col} controlId="formName">
                <Form.Control type="string"
                    placeholder={t('Name')}
                    value={name}
                    //autoComplete={true}
                    onBlur={() => {
                        dispatch({
                            type: "ORDER_SET",

                            order: {
                                ...order,
                                name
                            }

                        })
                    }}

                    onChange={(e) => setName(e.target.value)}
                />

            </Form.Group>
            <Form.Group className="mb-3" as={Col} controlId="formPhone">

                <div className="div-row">
                    <div
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Form.Control
                            placeholder={t('Cel Phone')}
                            type="string"
                            value={phone}
                            style={{
                                paddingLeft: 25
                            }}
                            onBlur={() => {

                                dispatch({
                                    type: "ORDER_SET",
                                    order: {
                                        ...order,
                                        phone
                                    }

                                })
                            }}
                            onKeyDown={(event) => {
                                if (
                                    event.key !== 'Backspace'
                                    && event.key !== 'ArrowLeft'
                                    && event.key !== 'ArrowRight'
                                    &&
                                    !/[0-9]/.test(event.key)
                                ) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setPhoneValidated(false);
                                setPhone(e.target.value)
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: 5,
                                left: 10,
                                fontWeight: 'bold',
                            }}
                        >+</div>
                    </div>
                </div>
            </Form.Group>
        </Row>
    }





    return <div>

        <Modal show={!!showMessageModal}
            style={{ backgroundColor: '#00000080', paddingBottom: 150 }}
            centered
            size='sm'
            onHide={handleCloseMessage}
        >

            <Modal.Body>

                {restaurant.header_logo &&
                    <div
                        style={{
                            marginBottom: 10
                        }}
                    >
                        <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 30 }} alt={`${restaurant.name} Logo`} />
                    </div>
                }

                {
                    errorMessage?.messages?.map((m, index) => {
                        return <div key={index}><Trans i18nKey={m} /></div>
                    })

                }
                {
                    !!showMessageModal.message && <div>{showMessageModal.message.replace("\n", "<br/>")}</div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseMessage}>
                    {t('OK')}
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal.Title className="div-row-space-between" style={{ padding: '10px 0px 0px 10px' }}
            onClick={() => {
                scroller.scrollTo(`order_top`, {
                    containerId: 'contained-modal-body',
                    smooth: true,
                    duration: 200,
                });
            }}
        >
            <div

            >
                <IoCart color="blue" size={20} style={{ marginRight: 10 }} />
                {t("Order")}
            </div>
            <CloseButton
                style={{
                    marginRight: 10,
                }}
                onClick={() => {
                    handleClose();
                }} />
        </Modal.Title>
        <hr />
        {orderStep === 0 &&
            <Modal.Body
                id="contained-modal-body"
                style={{
                    height: '100%',
                    paddingTop: 0
                }} >

                <>
                    <Element name="order_top" />
                    <Container style={{ marginBottom: 10, maxHeight: 250 }}>
                        <OrderItems />
                    </Container>

                </>

                <OrderTotal />
            </Modal.Body>
        }
        <Modal.Body
            style={{
                paddingTop: 0,
            }}
        >
            {orderStep === 0 &&
                <>
                    <Container style={{
                        marginTop: 0
                    }}>

                        {NamePhoneForm()}

                    </Container>


                    <Container>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <ButtonGroup aria-label="Dia"  >
                                {
                                    restaurant.on_site === 1 &&
                                    <Button
                                        variant={orderType === 'on_site' ? 'success' : 'dark'}
                                        onClick={() => {
                                            setDeliveryCost(null);
                                            setOrderType('on_site');
                                            scroller.scrollTo(`order_bottom`, {

                                                containerId: 'contained-modal-body',
                                                smooth: true,
                                                duration: 200,
                                            });
                                        }}

                                    >{t("on_site")}</Button>
                                }
                                {restaurant.pickup === 1 &&
                                    <Button
                                        variant={orderType === 'pickup' ? 'success' : 'dark'}
                                        onClick={() => {
                                            setOrderType('pickup');
                                            setDeliveryCost(null);
                                            scroller.scrollTo(`order_bottom`, {

                                                containerId: 'contained-modal-body',
                                                smooth: true,
                                                duration: 200,
                                            });
                                        }}
                                    >{t("pickup")}</Button>
                                }
                                {restaurant.delivery === 1 &&
                                    <Button
                                        variant={orderType === 'delivery' ? 'success' : 'dark'}
                                        onClick={() => {
                                            setOrderType('delivery');
                                            setDeliveryCost(null);
                                            scroller.scrollTo(`order_bottom`, {

                                                containerId: 'contained-modal-body',
                                                smooth: true,
                                                duration: 200,
                                            });
                                        }}
                                    >{t("delivery")}</Button>
                                }

                            </ButtonGroup>
                        </div>
                    </Container>
                </>

            }



            {orderStep === 1 &&

                <Container
                    style={{

                        padding: 0,
                    }}
                >

                    {/*<NamePhone />*/}
                    {orderType === 'delivery' &&
                        <>
                            <Row
                                style={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                }}
                            >
                                <Form.Group as={Col} xxs="12" controlId="validationFormik031">

                                    {orderType === 'delivery' && deliveryCost === null && !deliveryNotAvailable &&
                                        <div style={{
                                            textAlign: 'center',
                                            paddingBottom: 10,
                                            color: 'blue',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <IoHome />&nbsp;{t('we_need_delivery_address')}
                                        </div>
                                    }
                                    {orderType === 'delivery' && deliveryNotAvailable &&
                                        <div style={{ color: 'red' }}>
                                            {t('delivery_not_available')}
                                        </div>
                                    }

                                    <Address
                                        placeHolder={t('address')}
                                        fnNoAddress={() => {
                                            setDeliveryNotAvailable(false);
                                            setDeliveryCost(null);
                                        }}
                                        fnSelectAddress={(address, prediction, placeDetails) => {
                                            setAddress(address);


                                            let lat = placeDetails.geometry.location.lat();
                                            let lng = placeDetails.geometry.location.lng();


                                            let distanceKm = getDistanceFromLatLonInKm(restaurant_lat, restaurant_lng, lat, lng);
                                            distanceKm = distanceKm.toFixed(2);

                                            //setDistanceKm(distanceKm);
                                            let _deliveryCost;
                                            if (distanceKm > 0) {
                                                setDeliveryCost(null);
                                                _deliveryCost = fnCalculateDeliveryCost(distanceKm);
                                                if (!_deliveryCost) {
                                                    setTimeout(() => {
                                                        alert(t('delivery_not_available'));
                                                    }, 300);
                                                }
                                                setDeliveryCost(_deliveryCost);
                                            }


                                            dispatch({
                                                type: "ORDER_SET",

                                                order: {
                                                    ...order,
                                                    address,
                                                    lat,
                                                    lng,
                                                    distanceKm,
                                                    deliveryCost: _deliveryCost
                                                }

                                            })
                                        }}
                                    />


                                    <Form.Control
                                        style={{ margin: '10px 0px' }}
                                        type="text"
                                        placeHolder={t('address') + ' 2'}
                                        name="address2"
                                        value={address2}
                                        onChange={(e) => setAddress2(e.target.value)}
                                        onBlur={() => {
                                            dispatch({
                                                type: "ORDER_SET",

                                                order: {
                                                    ...order,
                                                    address2
                                                }

                                            })
                                        }}
                                    />
                                </Form.Group>

                            </Row>

                        </>

                    }

                    <OrderTotal />



                    <>
                        {
                            (orderType === 'delivery' || orderType === 'pickup') ?
                                <TimeSlotSelector
                                />
                                :
                                <Alert
                                    className="mb-3 text-center align-items-center justify-content-center d-flex flex-column"
                                >
                                    <h4>{t('table_number')}</h4>

                                    <Form.Control
                                        style={{ width: 70, marginTop: 10, fontSize: 20 }}
                                        type="string"
                                        value={tableNumber}
                                        autoFocus
                                        onChange={(e) => setTableNumber(e.target.value)}
                                    />
                                </Alert>
                        }
                        <Element name="order_bottom" />
                    </>


                </Container>
            }






            {
                orderStep === 3 &&
                <>
                    {
                        placing_order ?
                            <Container
                                style={{

                                    margin: '30px 0px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <Spinner animation="border" role="status" />

                            </Container>
                            :
                            <Container
                                className='div-col-center'
                            >
                                <div style={{ marginBottom: 10 }}>
                                    {
                                        restaurant.header_logo ?
                                            <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 60 }} alt={`${restaurant.name} Logo`} />
                                            :
                                            <>{restaurant.name}</>
                                    }
                                </div>
                                <h4>{t('order_confirmed_title')}</h4>

                                <p>{t('order_confirmed_text')}</p>

                                <p
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >

                                    {order_placed?.order_id} - {order_placed?.order_number}

                                    <br />
                                    {t(order_placed?.order_type)}
                                    <div>
                                        {order_placed?.order_type === 'on_site' &&
                                            "Mesa " + order_placed.order_table_number
                                        }
                                    </div>
                                    {order_placed?.order_name}
                                    <br />
                                    {order_placed?.order_phone}

                                </p>



                            </Container>
                    }
                </>
            }

        </Modal.Body>


        {
            orderStep === 3 && !placing_order &&
            <Modal.Footer>
                <Button
                    style={{ width: 200, margin: 'auto' }}
                    onClick={() => {
                        navigate(`/${restaurant.stub}/${order_placed?.order_number}/pay`)
                    }}
                >
                    {t('pay_now')}
                </Button>
            </Modal.Footer>
        }

        {
            orderStep !== 3 &&
            <Modal.Footer>
                {
                    orderStep === 0 && <div className='div-row-space-between' style={{ width: '100%' }}>
                        <Button variant="link" style={{ color: 'red' }} onClick={() => {

                            if (window.confirm(t('cancel_order_confirm'))) {
                                dispatch({
                                    type: 'ORDER_CLEAR'
                                });
                                handleClose();
                            }
                        }} >

                            <div className="div-row"><IoTrash /> {t('cancel')}</div>

                        </Button>

                        <Button variant="primary" size="lg"
                            style={{ width: 150 }}
                            disabled={
                                checkingPhone
                            }

                            onClick={() => {
                                setDeliveryCost(null);
                                setDeliveryNotAvailable(false)
                                scroller.scrollTo(`order_bottom`, {
                                    containerId: 'contained-modal-body',
                                    smooth: true,
                                    duration: 200,
                                });


                                fnGoStep1();

                            }}>
                            {
                                checkingPhone ?
                                    <Spinner animation="border" role="status" size='sm' />
                                    :
                                    <>
                                        {t('continue')}
                                    </>
                            }

                        </Button>

                    </div>

                }






                {
                    orderStep === 1 &&
                    <>

                        <div>
                            {restaurant?.restaurant_offline_message}
                        </div>

                        <div className='div-row-space-between' style={{ width: '100%' }}>
                            <Button variant="light" size="lg" onClick={() => {
                                setDeliveryCost(null);
                                setOrderStep(0);
                            }}  >

                                <div className="div-row"><IoChevronBack /> {t('back')}</div>

                            </Button>
                            <Button
                                variant="success" size="lg"
                                //disabled={orderType === 'delivery' && !deliveryCost}
                                onClick={() => {

                                    fnConfirmOrder()
                                }}
                            >
                                {t('confirm_order')}
                            </Button>



                        </div>
                    </>

                }

            </Modal.Footer>
        }

    </div >
}

export default Order;