import { combineReducers } from 'redux';

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
let dataState = {
    restaurant_id: 1,
    order: {
        id: makeid(6),
        status: 0,
        items: [],
        initialOrder: true
    },
    placing_order: false,
    web_order: {
        success: 0,
        fetching: false,
        order: {}
    }
}

const dataReducer = (state = dataState, action) => {
    switch (action.type) {

        case 'SET_DAY':
            return {
                ...state,
                day_payload: action.payload
            }

        case 'WEB_ORDER_SET':
            return {
                ...state,
                web_order: action.web_order
            }

        case 'PLACING_ORDER_START':
            return {
                ...state,
                placing_order: true
            }
        case 'PLACING_ORDER_END':
            return {
                ...state,
                placing_order: false,
                order_placed: action.order_placed
            }
        case 'ORDER_SET':
            return {
                ...state,
                order: action.order
            }
        case 'ORDER_CLEAR':

            localStorage.setItem('order', JSON.stringify({
                status: 0,
                id: null,
                items: []
            }));
            return {
                ...state,
                order: {
                    id: makeid(6),
                    status: 0,
                    items: []
                }
            }

        case 'ORDER_ADD_ITEM':

            return {
                ...state,
                order: {
                    ...state.order,
                    restaurantId: action.restaurant.id,
                    initialOrder: false,
                    status: 1,
                    items: [...state.order.items, action.action.payload.item]
                }
            }
        case 'ITEM_TOTAL_PRICE_SET':
            return {
                ...state,
                item_total_price: action.data.item_total_price
            }

        case 'ITEM_SET':
            return {
                ...state,
                item: action.data.item
            }

        case 'MENU_SET':


            return {
                ...state,
                menu: action.data.success ? action.data.menu : null,
                categories: action.data.success ? action.data.categories : null,
                restaurant: action.data.success ? action.data.restaurant : null
            }


        default:
            return state;
    }

}


const rootReducer = combineReducers({
    dataReducer,
})


export default rootReducer;