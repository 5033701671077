import React, { useState } from "react";
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as Scroll from 'react-scroll';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import Form from 'react-bootstrap/Form';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';

import InputGroup from 'react-bootstrap/InputGroup';

import { IoSearch, IoLogoWhatsapp, IoCart } from "react-icons/io5";

import { useTranslation } from "react-i18next";
import Order from '../components/Order';

import { LinkContainer } from 'react-router-bootstrap'
const img_root = process.env.REACT_APP_IMG_URL;
let Element = Scroll.Element;
let scroller = Scroll.scroller;

const fn_formatPrice = (price, currency, locale) => {
  if (!price) return "";
  let price_formatted = new Intl.NumberFormat(locale, {
    //trailingZeroDisplay: "stripIfInteger",
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol'
  }).format(price);

  return price_formatted;

};


const fnCompareBothItemsHaveSameExtras = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    let index = arr2.findIndex(e => e.name === arr1[i].name);
    if (index === -1) return false;
  }
  for (let i = 0; i < arr2.length; i++) {
    let index = arr1.findIndex(e => e.name === arr2[i].name);
    if (index === -1) return false;
  }
  return true;
}


const Item = React.memo(({
  item,
  onClick,
}) => {



  return <Col

    md={4}
    lg={4}
    onClick={onClick}
    className="f-container"
  >
    <Card
      className="product-card"
    >

      {!!item.img &&
        <div className="div-img" >

          <img className="f-img" src={img_root + '/' + item.img} alt={item.name} loading="lazy" />

        </div>
      }

      <div className="div-content">
        <Card.Body>
          <Card.Subtitle>{item.name}</Card.Subtitle>
          <Card.Text as="div" >
            <div className='text-small-grey' dangerouslySetInnerHTML={{ __html: item.description.replaceAll("\n", "<br/>") }}></div>

            <div className='text-small-grey'  >
              {((item.description || "") === "") && JSON.parse(item.extras).map((e, index) => {
                return (
                  <span key={index} className="f-extra-name">{e.name}</span>

                )
              })}
            </div>
            <span className="f-price notranslate">{item.price_formatted}</span>
          </Card.Text>
        </Card.Body>
      </div>
    </Card>
  </Col>

})

const ItemDetails = ({
  handleClose
}) => {
  const t = useTranslation().t;
  const dispatch = useDispatch();
  const dataReducer = useSelector((state) => state.dataReducer);
  const {
    item,
    restaurant,
    order
  } = dataReducer;

  const [orderStep, setOrderStep] = useState(0);
  const [img_width, set_img_width] = useState();
  const [units, setUnits] = useState(1);
  const [itemPrice, setItemPrice] = useState(item.price);
  const [itemOrderedExtras, setItemOrderedExtras] = useState([]);
  const [itemInstructions, setItemInstructions] = useState('');
  const [itemRequiredOption, setItemRequiredOption] = useState({});

  const fnAddToOrder = () => {
    if (item.required_option !== '[]' && !itemRequiredOption.name) {
      alert(t('select_an_option'));
      return;

    }


    let items = order?.items || [];
    let existingItem = items.findIndex(i => i.id === item.id);

    //todo: check if more than 1 item already exists in order
    //todo: https://trello.com/c/6GwvJaIo

    let itemAlreadyExists = false;


    if (existingItem !== -1) {
      itemAlreadyExists = true;
      if (items[existingItem].extras_ordered.length > 0 || itemOrderedExtras.length > 0) {
        itemAlreadyExists = fnCompareBothItemsHaveSameExtras(items[existingItem].extras_ordered, itemOrderedExtras)
      }

      if (itemRequiredOption.name !== items[existingItem].required_option?.name) {
        itemAlreadyExists = false;
      }
    }

    if (itemAlreadyExists) {
      let _order = {
        ...order,
        items: [
          ...order.items.slice(0, existingItem),
          {
            ...order.items[existingItem],
            units_ordered: order.items[existingItem].units_ordered + units,
            notes: (itemInstructions || "").trim() !== "" ? ((order.items[existingItem].notes || "") + " " + (itemInstructions || "")) : order.items[existingItem].notes
          },
          ...order.items.slice(existingItem + 1)
        ]
      }

      dispatch({
        type: 'ORDER_SET',
        order: _order
      });

    } else {
      dispatch({
        type: 'ADD_TO_ORDER',
        payload: {
          item: {
            ...item,
            units_ordered: units,
            price_ordered: itemPrice,
            extras_ordered: itemOrderedExtras,
            required_option: itemRequiredOption,
            notes: itemInstructions === "" ? null : itemInstructions
          }
        }
      });



    }


    handleClose();
  }


  React.useEffect(() => {
    set_img_width(document.getElementById('contained-modal-body')?.clientWidth);

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>

    <div
      className="f-close-button"
      style={{

        width: orderStep === 0 ? 50 : 25,
        height: orderStep === 0 ? 50 : 25,

      }}
    >
      <CloseButton
        variant="white"
        onClick={() => {
          if (orderStep === 0) {
            handleClose();
          }
          else {
            setOrderStep(0);
          };
        }} />
    </div>

    <Modal.Body
      id="contained-modal-body" style={{ padding: 0, minHeight: 250 }}

    >

      {item && <Form>
        {orderStep !== 0 ?
          <div style={{ height: 20 }}></div>
          :
          !!item.img &&
          <img className="f-img-0" src={img_root + '/' + item.img} style={{ height: img_width }} alt={item.name} loading="lazy" />
        }
        <div style={{
          padding: 10
        }}>
          <h3>{item.name}</h3>

          {orderStep === 0 &&
            <>
              <span dangerouslySetInnerHTML={{ __html: item.description.replaceAll("\n", "<br/>") }}></span>
              <div style={{ fontSize: 14, color: '#999', }}>
                {JSON.parse(item.extras).length > 0 && <div style={{ color: '#000' }}>
                  Extras
                </div>

                }
                {JSON.parse(item.extras).map((e, index) => {
                  return (
                    <span key={index} className="f-extra-name">{e.name} </span>

                  )
                })}
              </div>
            </>
          }
          {orderStep === 1 &&
            <Container>

              <ButtonGroup aria-label="Basic example">
                <Button variant="primary" style={{ width: 60 }} onClick={() => units > 1 ? setUnits(units - 1) : 1}>-</Button>
                <Button variant="light" style={{ width: 70 }}>{units}</Button>
                <Button variant="primary" style={{ width: 60 }} onClick={() => setUnits(units + 1)}>+</Button>
              </ButtonGroup>








              <div style={{ marginTop: 10 }}>
                {JSON.parse(item.required_option).map((e, index) => {
                  return <div key={index} className="div-row-space-between">
                    {
                      orderStep === 0 ?
                        `${e.name}`
                        :
                        <Form.Check
                          onChange={(c_e) => {
                            setItemRequiredOption(e);
                            setItemPrice(parseFloat(e.price));
                          }}
                          type={"radio"}
                          id={`required-${index}`}
                          required_name={e.name}
                          label={e.name}
                          style={{
                            width: '100%',
                            fontSize: 17
                          }}
                          name="required_option"
                        />

                    }
                    <div>
                      {fn_formatPrice(e.price, restaurant.currency, restaurant.locale)}
                    </div>
                  </div>
                })}
              </div>










              <div style={{ marginTop: 10 }}>
                {JSON.parse(item.extras).map((e, index) => {
                  return <div key={index} className="div-row-space-between">
                    {
                      orderStep === 0 ?
                        `${e.name}`
                        :
                        <Form.Check
                          onChange={(c_e) => {
                            setItemOrderedExtras(c_e.target.checked ? [...itemOrderedExtras, e] : itemOrderedExtras.filter((e2) => e2.name !== e.name))
                            setItemPrice(c_e.target.checked ? parseFloat(itemPrice) + parseFloat(e.price) : parseFloat(itemPrice) - parseFloat(e.price))
                          }}
                          type={"checkbox"}
                          id={`extra-${index}`}
                          extra_name={e.name}
                          label={e.name}
                          style={{
                            width: '100%',
                            fontSize: 17
                          }}
                        />

                    }
                    <div>
                      {fn_formatPrice(e.price, restaurant.currency, restaurant.locale)}
                    </div>
                  </div>
                })}
              </div>




            </Container>
          }


          {
            orderStep === 1

            &&
            <Container style={{ padding: 0, marginTop: 10 }}>

              <div>
                {item.instructions || t("Notes")}
              </div>
              <textarea
                onChange={(e) => {
                  setItemInstructions(e.target.value);
                }}
                value={itemInstructions}
                style={{
                  width: '100%',
                  padding: 10
                }}
              >
              </textarea>
            </Container>
          }


        </div>
      </Form>}

    </Modal.Body>
    <Modal.Footer>

      <div>
        {restaurant?.restaurant_offline_message}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          alignItems: "center"
        }}
      >

        {orderStep === 0 ?
          <h4>{item?.price_formatted}</h4>
          :
          <h4>{fn_formatPrice(itemPrice * units, restaurant.currency, restaurant.locale)}</h4>

        }


        {!restaurant?.restaurant_offline &&
          <>
            {orderStep === 0 &&
              <Button variant="primary" size="lg"

                onClick={() => {
                  setOrderStep(1);
                }}
              >
                {t("add_to_order")}


              </Button>
            }

            {orderStep === 1 &&

              <Button variant="success" size="lg"

                onClick={() => fnAddToOrder()}
              >

                {t("add_to_order")}
              </Button>
            }
          </>
        }



      </div>
    </Modal.Footer>
  </>
}


function Menu({
  subdomain,
}) {
  // eslint-disable-next-line 
  const { t, i18n } = useTranslation();
  let restaurant_stub = useParams().restaurant_stub;


  if (!restaurant_stub && subdomain) {
    restaurant_stub = subdomain;
  }

  const dispatch = useDispatch();
  const dataReducer = useSelector((state) => state.dataReducer);
  const {
    menu,
    categories,
    restaurant,
    order,
  } = dataReducer;



  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const [showOrder, setShowOrder] = useState(false);
  const handleClose2 = () => {
    setShowOrder(false);
  };
  const [expanded, setExpanded] = React.useState(false);
  const [search, setSearch] = useState(localStorage.getItem('search') || "");



  React.useEffect(() => {

    let _order = localStorage.getItem('order');

    _order = _order ? JSON.parse(_order) : {
      status: 0,
      items: []
    }

    dispatch({
      type: "ORDER_SET",
      order: _order
    });
    if (menu) return;
    dispatch({
      type: "MENU_FETCH",
      restaurant_stub
    });


  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(() => {
    if (!order.initialOrder) {
      localStorage.setItem('order', JSON.stringify(order));
      if (order.name && order.name.trim() !== "") {
        localStorage.setItem('order_name', order.name);
      }
      if (order.phone && order.phone.trim() !== "") {
        localStorage.setItem('order_phone', order.phone);
      }
      if (order.address1 && order.address1.trim() !== "") {
        localStorage.setItem('order_address1', order.address1);
      }
      if (order.address2 && order.address2.trim() !== "") {
        localStorage.setItem('order_address2', order.address2);
      }
    }
  }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {

    if (restaurant) {
      i18n.changeLanguage(restaurant.locale);
    }

    if (restaurant && order.restaurantId) {
      if (order.restaurantId !== restaurant.id) {
        console.warn("DIFERENT RESTAURANT");
        dispatch({
          type: "ORDER_CLEAR"
        })
      }
    }
  }, [restaurant]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (categories?.length) {
      setSelectedCategory(categories[0].name);

    }
  }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!restaurant) {
    return <Container
      style={{
        margin: 'auto',
        maxWidth: 350,
        padding: 50
      }}

    >
      <Alert
        className="div-row-space-between"
      >
        <div></div>
        <Spinner size="lg" />

        <div></div>
      </Alert>
    </Container>
  }

  return (<>

    <div
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <HelmetProvider>
        <Helmet>
          <title>{restaurant?.name || "Saga Menu"}</title>
        </Helmet>
      </HelmetProvider>
      <Container

      >

        <Navbar bg="light" expand="lg" fixed="top"
          expanded={expanded}
          style={{
            paddingBottom: 5, display: 'flex', flexDirection: 'column', justifyItems: 'center',
            margin: 'auto'
          }}
          onToggle={() => setExpanded(!expanded)}
        >

          <Container >

            {!restaurant ?

              <Spinner animation="grow" />
              :
              <Navbar.Brand href="#home" >
                <div className="div-row">
                  {
                    restaurant.header_logo ?
                      <img src={`https://yum-food.s3.amazonaws.com/${restaurant.id}/logo.png`} style={{ height: 30 }} alt={`${restaurant.name} Logo`} />
                      :
                      <>{restaurant.name}</>
                  }

                </div>
              </Navbar.Brand>
            }

            {
              !!restaurant?.restaurant_offline_message && !!restaurant?.restaurant_offline &&
              <div
                style={{ textAlign: 'center', flex: 1 }}
              >
                {restaurant?.restaurant_offline_message}
              </div>
            }

            <div className='div-row' >
              {!restaurant?.restaurant_offline &&
                <>
                  {order?.items?.length > 0 && !!menu &&
                    <div className='div-row' onClick={() => setShowOrder(true)} >
                      <h3 style={{ padding: 0, margin: 0 }}><Badge bg="primary">{order.items.length}</Badge></h3>

                      <IoCart color="#316CF4" size={28} style={{ marginRight: 20 }} />
                    </div>
                  }
                </>


              }
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>


            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">

                <Nav className="me-auto">

                  <Nav.Link
                    target="_blank" rel="noreferrer" href={`https://wa.me/${restaurant.whatsapp_api}`} className="div-row"

                  ><IoLogoWhatsapp />+{restaurant.whatsapp_api}</Nav.Link>

                  <LinkContainer to={`/${restaurant_stub}/o/`} className="div-row" >
                    <Nav.Link >{t('order_lookup')}</Nav.Link>
                  </LinkContainer>

                </Nav>

              </Nav>
            </Navbar.Collapse>

          </Container>
          <Container
            style={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {categories?.length > 0 ?

              <DropdownButton
                className={"d-grid"}
                id="dropdown-basic-button"
                title={selectedCategory}
                variant="success"
                size="md"
              >


                {categories?.map((c) => (
                  <Dropdown.Item key={c.id}
                    onClick={() => {

                      setExpanded(false);
                      setSelectedCategory(c.name);
                      scroller.scrollTo(`cat_${c.id}`, {

                        offset: -100,
                      })
                    }}
                  >{c.name}</Dropdown.Item>

                ))}
              </DropdownButton>

              :
              <span></span>
            }


            <div style={{
              paddingLeft: 10,
              flex: 1,
              maxWidth: 300,
              position: 'relative'
            }}>

              <InputGroup >
                <Form.Control
                  aria-label="Search"
                  aria-describedby="search-icon"
                  placeholder={t('Search')}
                  onChange={(e) => {
                    localStorage.setItem('search', e.target.value);
                    setSearch(e.target.value)
                  }}
                  value={search}
                />
                <InputGroup.Text id="search-icon" >
                  <IoSearch size={22} />
                </InputGroup.Text>
              </InputGroup>

              {search !== '' &&
                <div
                  className="btn-close"
                  style={{
                    width: 7,
                    height: 7,
                    position: 'absolute',
                    right: 60,
                    bottom: 10,
                    cursor: 'pointer',
                    opacity: 0.4,
                    zIndex: 1000,
                  }}
                  onClick={(e) => { e.preventDefault(); setSearch(''); localStorage.setItem('search', ''); }}
                />
              }

            </div>

          </Container>
        </Navbar>




        <Container style={{
          marginTop: 100,
          padding: 0
        }}>
          {search !== '' && <br />}
          {menu?.length === 0 && <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Menu is empty
          </div>}

          {categories?.map((c, index) => {
            return (
              <Container key={index} style={{ padding: 0 }}>

                <Element name={`cat_${c.id}`}></Element>

                {search === '' &&
                  <Row style={{ padding: 0 }}>
                    <Col style={{ padding: 0 }}>
                      <h2 className='category-block'>{c.name}</h2>
                    </Col>
                  </Row>
                }

                <Row
                >
                  {
                    menu?.filter(
                      (item) => c.name === item.cat
                        &&
                        (
                          search === ""
                          ||
                          item.name.toLowerCase().includes(search.toLowerCase())
                          ||
                          item.cat.toLowerCase().includes(search.toLowerCase())



                        )
                    ).map((item, index) => {

                      return <Item
                        item={item}
                        key={index}
                        onClick={() => {
                          setExpanded(false);
                          setShow(true)
                          dispatch({
                            type: "ITEM_SET",
                            data: {
                              item
                            }
                          });
                        }}
                      />
                    })
                  }
                </Row>
              </Container>
            )
          })}


        </Container>


        <div style={{
          height: 200
        }}>
        </div>
      </Container>

      {
        !restaurant?.restaurant_offline &&

        <div className="footer">

          {order.items.length > 0 &&
            <div
              style={{ width: '100%', maxWidth: 450, margin: 'auto' }}
            >
              <Button
                style={{
                  width: '100%',
                }}
                disabled={!menu}
                onClick={() => {
                  setShowOrder(true);
                }}
                variant="primary"
                size="lg"
              >
                Orden&nbsp;&nbsp;<Badge bg="info">{order.items.length}</Badge>

              </Button>
            </div>
          }
        </div>
      }



      <Modal
        show={showOrder && !show}
        onHide={handleClose2}
        size="md"
        backdrop={true}
        scrollable={true}

      >

        <Order
          handleClose={handleClose2}
          showItemModal={() => setShow(true)}
        />

      </Modal>


      <Modal
        show={show}
        onHide={handleClose}
        //fullscreen={'md-down'}
        backdrop={true}
        scrollable={true}

      >

        <ItemDetails
          handleClose={handleClose}
        />

      </Modal>

    </div>

  </>
  );
}

export default Menu;
