import { call, put, takeLatest, select } from 'redux-saga/effects';

import api from './api';

export const getRestaurant = (state) => state.dataReducer.restaurant;


function* fn_MenuFetch(action) {
    try {
        //let phone = yield select(getPhone);

        const data = yield call(api.fetch, action);
        yield put({ type: "MENU_SET", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}


function* fn_ItemFetch(action) {
    try {
        //let phone = yield select(getPhone);

        //const data = yield call(api.fetch, action);
        yield put({ type: "ITEM_SET", action });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}

function* fn_AddToOrder(action) {
    try {
        let restaurant = yield select(getRestaurant);
        //const data = yield call(api.fetch, action);
        yield put({ type: "ORDER_ADD_ITEM", action, restaurant });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}


function* fn_OrderConfirm(action) {
    try {
        //let phone = yield select(getPhone); 
        const data = yield call(api.order_put, action);
        yield put({
            type: "PLACING_ORDER_END",
            order_placed: data.order
        });
        if (data.success) {
            yield put({ type: "ORDER_CLEAR" });
        } else {
            alert("Error: " + JSON.stringify(data))
        }


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_OrderConfirm", message: e.message });
    }
}


function* fn_OrderFetch(action) {
    try {
        //let phone = yield select(getPhone); 
        yield put({
            type: "WEB_ORDER_SET",
            web_order: { fetching: true, order: {} }
        });
        const data = yield call(api.order_fetch, action);

        yield put({
            type: "WEB_ORDER_SET",
            web_order: data
        });



    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_OrderFetch", message: e.message });
    }
}




export default function* rootSaga() {

    yield takeLatest('MENU_FETCH', fn_MenuFetch);
    yield takeLatest('ITEM_FETCH', fn_ItemFetch);
    yield takeLatest('ORDER_FETCH', fn_OrderFetch);


    yield takeLatest('ADD_TO_ORDER', fn_AddToOrder);
    yield takeLatest('ORDER_CONFIRM', fn_OrderConfirm);



}

