import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './app/store'
import 'bootstrap/dist/css/bootstrap.min.css';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Menu from './app/screens/Menu';
import A404 from './A404';

import OrderDetails from './app/screens/OrderDetails';

import Pay from './app/screens/Pay';
import PayComplete from './app/screens/PayComplete';

//import MenuItem from './app/screens/MenuItem';
//import Address from './app/screens/AddressTest';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "on_site": "On Site",
          "delivery": "Delivery",
          "pickup": "Pickup",
          "address": "Address",
          "continue": "Continue",
          "confirm_order": "Confirm",
          "back": "Back",
          "cancel": "Cancel",
          "cancel_order_confirm": "Are you sure you want to cancel your order?",
          "order_confirmed_title": "Order Confirmed!",
          "order_confirmed_text": "Your order has been confirmed.",
          "phone_required_format": "<br/>Include your country code and area code.<br/>For example, +1 555 555 5555",
          "phone_check_error_TOO_SHORT": "Phone number is too short",
          "phone_check_error_TOO_LONG": "Phone number is too long",
          "phone_check_error_INVALID_BUT_POSSIBLE": "Phone number is invalid",
          "phone_check_error": "Error",
          "address_required": "Address is required",
          "error": "Error",
          "table_number": "Table Number",
          "error_name": "Name is required",
          "error_phone": "Phone is required",
          "error_table_number": "Table number is required",
          "error_time_slot": "Time is required",
          "error_day": "Day is required",
          "close": "Close",
          "add_to_cart": "Add to Order",
          "item_added_to_order": "Item added to order",
          "select_an_option": "Please select an option",
          "loading": "Loading...",
          "order_number": "Order Number",
          "search": "Search",
          "order_lookup": "Order Lookup",
          "order_not_found": "Order not found",
          "add_to_order": "Add to Order",
          "order_payment_succesful": "Order Payment Approved",
          "order_payment_failed": "Order Payment Failed",

          "order_has_been_paid": "Thank you<br/>Order has been paid",
          "payment_link": "Pay Here",
          "order_details": "Order",
          "pay_now": "Pay Now",
          "delivery_not_available": "Delivery not available to this address",
          "we_need_delivery_address": "We need your delivery address",
          "select_day": "Select Day",
          "must_select_delivery_address": "You must select a delivery address",
          'closed_day': 'Sorry! We are closed on this day',
        }
      },
      es: {
        translation: {
          "on_site": "En el local",
          "delivery": "Delivery",
          "pickup": "Recoger",
          "address": "Dirección",
          "continue": "Continuar",
          "confirm_order": "Confirmar",
          "back": "Regresar",
          "cancel": "Cancelar",
          "cancel_order_confirm": "¿Estás seguro que quieres cancelar tu orden?",
          "order_confirmed_title": "¡Orden confirmada!",
          "order_confirmed_text": "Tu orden ha sido confirmada.",
          "phone_required_format": "<br/>Incluye tu código de país y código de área.<br/>Por ejemplo, +1 555 555 5555",
          "phone_check_error_TOO_SHORT": "El número de teléfono es muy corto",
          "phone_check_error_TOO_LONG": "El número de teléfono es muy largo",
          "phone_check_error_INVALID_BUT_POSSIBLE": "El número de teléfono es inválido",
          "phone_check_error": "Error",
          "address_required": "La dirección es requerida",
          "error": "Error",
          "table_number": "Número de Mesa",
          "error_name": "El nombre es requerido",
          "error_phone": "El teléfono es requerido",
          "error_table_number": "El número de mesa es requerido",
          "error_time_slot": "La hora es requerida",
          "error_day": "El día es requerido",
          "close": "Cerrar",
          "add_to_order": "Agregar a la orden",
          "item_added_to_order": "Item agregado a la orden",
          "select_an_option": "Por favor selecciona una opción",
          "loading": "Actualizando",
          "order_number": "Número de Orden",
          "search": "Buscar",
          "order_lookup": "Buscar Orden",
          "order_not_found": "Orden no encontrada",
          "Search": "Buscar",
          "Notes": "Notas",
          "Today": "Hoy",
          "Tomorrow": "Mañana",
          "Name": "Nombre",
          "Cel Phone": "Celular",
          "Order": "Orden",
          "order_payment_succesful": "Gracias!<br/>Tu orden está confirmada",
          "order_payment_failed": "Pago de orden falló",
          "order_has_been_paid": "Gracias<br/>La orden ya fue pagada",
          "payment_link": "Paga Aquí",
          "order_details": "Detalles",
          "pay_now": "Pagar",
          "delivery_not_available": "Entrega no está disponible a esta zona",
          "we_need_delivery_address": "Necesitamos tu dirección de entrega",
          "select_day": "Selecciona el día",
          "must_select_delivery_address": "Debes seleccionar una dirección de entrega",
          'closed_day': '¡Lo sentimos! Estamos cerrados en este día',
        }
      }
    },
    lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
const root = ReactDOM.createRoot(document.getElementById('root'));


let subdomain = window.location.host.split(".")[0]
console.log("subdomain", subdomain)
root.render(

  <Provider store={store}>
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <BrowserRouter>
        <Routes>

          <Route path='/:restaurant_stub'
            element={<Menu
              subdomain={subdomain}
            />}
          />



          <Route path='/:restaurant_stub/o/:order_number'
            element={<OrderDetails

            />}
          />
          <Route path='/:restaurant_stub/o/'
            element={<OrderDetails />}
          />

          <Route path='/:restaurant_stub/:order_number/pay/'
            element={<Pay />}
          />
          <Route path='/:restaurant_stub/:order_number/pay_complete'
            element={<PayComplete />}
          />
          {/*  
          <Route path='/:restaurant_stub/address'
            element={<Address />}
          />
          */}
          {/*  
          <Route path='/:restaurant_stub/:item'
            element={<MenuItem />}
          />
          */}

          <Route path='/'
            element={<Menu
              subdomain={subdomain}
            />}
          />

          <Route
            path="*"
            element={<A404 />}
          />

        </Routes>
      </BrowserRouter>

    </ThemeProvider>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
